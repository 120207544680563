<p-dialog [styleClass]="'acv_dialog'" [draggable]="false" [closable]="false" [style]="{'width': '30%'}" [modal]="true"
    [visible]="show_Dialog">

    <ng-template pTemplate="header">
        <div class="flex wd-100 dialog_header_wrapper align-items-center justify-content-between ng-star-inserted">
            <span class="text-xl font-bold">Reason</span>
            <p-button icon="pi pi-times" (onClick)="hide()"
                styleClass="p-button-rounded p-button-danger p-button-text p-button-raised"></p-button>
        </div>
    </ng-template>

    <div class="dialog_content_wrapper">
        <form [formGroup]="rejectForm" *ngIf="rejectForm">
            <div class="grid m-0">
                <div class="col-12 md:col-12 lg:col-12 xl:col-12">
                    <acv-text-input [form]="rejectForm" ControlName="reason" [ControlType]="'text-area'" [ControlLabelText]="'Reason'"
                        [PlaceHolderText]="'Enter Reason'" [characterLimit]="250" [Required]="true"
                        [IsSubmited]="is_form_submit" [ShowWordCount]="true"></acv-text-input>
                </div>
            </div>
        </form>
    </div>

    <ng-template pTemplate="footer">
        <p-button
        (onClick)="handleCancelConfirm()"
        type="button"
        label="Cancel"
        class="p-button-text elevated-btn secondary mr-2"
      ></p-button>
      <p-button
        type="button"
        [label]="'Submit'" 
        (onClick)="handleSubmitForm()"
        [loading]="isLoading"
        class="p-button-text elevated-btn primary mr-2"
      ></p-button>
    </ng-template>
</p-dialog>