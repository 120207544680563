<p-dialog [styleClass]="'popup_export'" [draggable]="false" [closable]="false" [style]="{'width': tracking_info ? (grid_data.length>0 ? '50%' : '35%') : '25%'}" [modal]="true"
    [visible]="show_NotesDialog">

    <ng-template pTemplate="header">
            <div class="flex wd-100 dialog_header_wrapper align-items-center justify-content-between ng-star-inserted">
                <span class="text-xl font-bold">{{dialog_header}}</span>
                <p-button icon="pi pi-times" (onClick)="hide()" 
                styleClass="p-button-rounded p-button-danger p-button-text p-button-raised"></p-button>
    
            </div>

        <div *ngIf="tracking_info" style="padding:0.5rem 0 0.5rem 0" class="col-12 flex justify-content-center mb-2">
            <p class="line"></p>
        </div>

    </ng-template>
        <ng-container *ngIf="tracking_info else info">
            <div class="export_content_wrapperNew flex grid" [style]="{'margin-bottom': grid_data.length>0 ? '0' : '3rem'}">
                <div [ngClass]=" grid_data.length>0 ? 'col-12 md:col-4 lg:col-4 flex flex-column justify-content-center' : 'col-12 md:col-6 lg:col-6 flex flex-column justify-content-center'" style="margin-bottom: 0.25rem; padding-left: 15px;" *ngFor="let note of notes_data">
                    <p class="detail-heading">{{note.title}}</p>
                    <p (click)="note.link && cellClicked(note.value)" [ngStyle]="{'margin-top':'0.5rem', 'cursor': note.link ? 'pointer' : 'default', 'text-decoration': note.link ? 'underline' : 'none'}" class="detail-data">{{note.value}} <i *ngIf="note.link" class="pi i-arrow-topR"></i></p>
                </div>

            </div>
        </ng-container>


        <ng-template #info>
            <div class="export_content_wrapper flex flex-column">
                <ul>
                    <li class="content_title" *ngFor="let note of notes_data">{{note.text}}</li>
                </ul>
            </div>
        </ng-template>

        <ng-template pTemplate="footer" style="padding-bottom: 18px !important;">
            <ng-container *ngIf="tracking_info && grid_data.length>0">
                
                <div style="padding:0.5rem 9px 0.5rem 9px" class="col-12 flex justify-content-center">
                    <p class="line"></p>
                </div>
                <div style="height: 13rem;">
                    <acv-grid 
                    [GridColumns]="grid_title" 
                    [GridDataSource]="grid_data"
                    [AllowSelection]="false"
                    [AllowSorting]="true"
                    [AllowPagination]="false"
                    [AllowColumnReOrdering]="false"
                    [ShowLineLevelAction]="false"
                    (cellClicked)="cellClicked($event)"
                     ></acv-grid>
                </div>
            </ng-container>
        </ng-template>
        

</p-dialog>
