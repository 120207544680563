import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { ApiHttpService } from 'src/app/core/services/apiHttpcall.service';
import { ProfileService } from 'src/app/core/services/profile.service';
import { Location } from '@angular/common';
import { MessageService } from 'primeng/api';
import { BehaviorSubject, Observable } from 'rxjs';

export interface profileData{
  Image_URL: any;
}

@Injectable({
  providedIn: 'root'
})
export class DataResolverService implements Resolve<any> {

  private _profile_data = new BehaviorSubject<profileData>({
    Image_URL: ''
  });

  private _profile_data$ = this._profile_data.asObservable();

  constructor(private httpService: ApiHttpService, 
              private profileService: ProfileService, 
              private routeLocation: Location,
              private messageService: MessageService,
              private router: Router,) { }

  async resolve(route: ActivatedRouteSnapshot){
    const resp = await this.httpService.get('/me', {
      token: this.profileService.gettoken(),
    });
    if (resp.status == 200) {
      this.profileService.setuserProfileData(resp.data);
      this.profileService.setPermissions();
    }
    else if(resp.error.error.code == 401){
      this.messageService.add({ severity: 'error', summary: 'Failed', detail: resp?.error?.message, key: 'app_notiy' });
      this.router.navigate(['/login']);
    }
    return resp;
  }
  
  callPreviousToute(){
    this.routeLocation.back();
  }

  // get latest profile image
  get_UserProfile(): Observable<profileData> {
    return this._profile_data$;
  }

  // set latest profile image
  set_UserProfile(latestValue: profileData){
    return this._profile_data.next(latestValue);
  }

  

}
