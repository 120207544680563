<p-dialog
  [styleClass]="'popup_upload'"
  [draggable]="false"
  [closable]="false"
  [style]="{ width: '40%' }"
  [modal]="true"
  [visible]="show_uploadDialog"
>
  <ng-template pTemplate="header">
    <div
      class="flex wd-100 dialog_header_wrapper align-items-center justify-content-between ng-star-inserted"
    >
      <span class="text-xl font-bold">{{ dialog_header }}</span>
      <p-button
        icon="pi pi-times"
        (onClick)="hide()"
        styleClass="p-button-rounded p-button-danger p-button-text p-button-raised"
      ></p-button>
    </div>
  </ng-template>

  <div
    class="upload_content_wrapper"
    [ngClass]="
      genericError_Visible
        ? 'flex gap-4 flex-column align-items-center justify-content-end'
        : 'flex flex-column align-items-center justify-content-center'
    "
    (files)="onFileDrop($event)"
    DragndropFile
  >
    <ng-container>
      <div><img src="../../../../assets/images/upload.svg" /></div>
      <p class="content_label mt-2">
        Drag and Drop file here or
        <span
          ><p-fileUpload
            #fubauto
            mode="basic"
            [auto]="true"
            [classList]="'upload_browse'"
            [multiple]="true"
            [maxFileSize]="5242880"
            [accept]="'.pdf, .jpg, .jpeg, .png'"
            (onSelect)="onBasicUploadAuto($event)"
            chooseLabel="Choose file"
          >
          </p-fileUpload>
        </span>
      </p>

      <p-messages
        severity="error"
        [className]="'upload-error'"
        *ngIf="uploadError_Visible"
      >
        <ng-template pTemplate>
          <p-button
            icon="pi pi-times"
            (onClick)="fubauto.clear(); uploadError_Visible = false"
            styleClass="p-button-rounded p-button-danger p-button-text p-button-raised"
          ></p-button>
          <span class="custom-message">{{ uploadError_Message }}</span>
        </ng-template>
      </p-messages>
    </ng-container>
  </div>
  <div class="flex justify-content-between">
    <span class="typo-supported-format">Support formats: PDF,JPEG,PNG</span>
    <span class="typo-supported-format">Maximum size: 5 MB</span>
  </div>
  <div class="images-section" *ngIf="uploadedFiles.length">
    <ul>
      <li
        class="flex justify-content-between p-1"
        *ngFor="let file of uploadedFiles"
      >
        <div (click)="imageView(file)" class="cursor-pointer fileNameText">{{ file.name }}</div>
        <div>
          <i class="cursor-pointer pi pi-times" (click)="removeImage(file)"></i>
        </div>
      </li>
    </ul>
  </div>

  <div class="dialog_footer mt-4">
    <div class="flex justify-content-end">
      <p-button
        type="button"
        label="Cancel"
        class="p-button-text elevated-btn secondary mr-2"
        (click)="hide()"
      ></p-button>
      <p-button
        type="button"
        label="Upload"
        class="p-button-text elevated-btn primary mr-2"
        (onClick)="submit_file()"
        [disabled]="!Is_ValidFile"
      ></p-button>
    </div>
  </div>
</p-dialog>
