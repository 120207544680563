<div
  class="h-30rem w-12 flex flex-column justify-content-center login-form mt-8"
>
  <form [formGroup]="forgetPassForm">
    <h3 class="font-poppin text-align-center fw-500 fs-30 mt-0 mb-0">
      Forgot Password
    </h3>
    <p class="font-poppin text-align-center fw-400 fs-15 mt-2 mb-0">
      Enter your details to get a reset password.
    </p>
    <div
      class="flex flex-column justify-content-start flex-wrap mt-4 input-div"
    >
      <div class="relative mt-6">
        <acv-text-input
          (input)="handleInput($event)"
          [form]="forgetPassForm"
          ControlName="email"
          [IsSubmited]="
            (!forgetPassForm.controls['email'].valid &&
              submitted &&
              !resp?.error &&
              forgetPassForm.controls['email'].value) ||
            (resp && resp?.error && forgetPassForm.controls['email'].value)
          "
          [ControlLabelText]="'Email Address or Phone Number'"
          [PlaceHolderText]="'Enter Email or Phone Number'"
          [Required]="false"
          [ShowWordCount]="false"
        ></acv-text-input>
        <p
          class="absolute control_errorMsg font-poppin fw-400 fs-12 mt-0 mb-4 text-color-red1"
          *ngIf="
            !forgetPassForm.controls['email'].valid && submitted && !resp?.error
          "
        >
          Please enter your email or phone number.
        </p>
        <p
          class="absolute control_errorMsg font-poppin fw-400 fs-12 mt-0 mb-4 text-color-red1"
          *ngIf="resp && resp?.error && forgetPassForm.controls['email'].value"
        >
          {{ resp?.error.message }}
        </p>
      </div>
    </div>
    <p-button (click)="handleSubmit()">
      <div class="button-hover-effect1 absolute"></div>
      <div class="button-hover-effect2 absolute"></div>
      <span
        class="font-poppin fs-14 fw-600 line-height-2 text-align-center"
        style="padding-left: 40%; position: absolute"
        >Send</span
      >
    </p-button>
    <div class="flex flex-row justify-content-center mt-4">
      <span (click)="navigateToLogin()" class="font-poppin fs-15 fw-400" style="color: #434343; cursor: pointer;">
        Return to Login
      </span>
    </div>
  </form>
</div>
