<p-dialog
  [styleClass]="'popup_chips'"
  [draggable]="false"
  [closable]="false"
  [style]="{ width: '30%' }"
  [modal]="true"
  [visible]="show_Dialog"
>
  <ng-template pTemplate="header">
    <div
      class="flex wd-100 dialog_header_wrapper align-items-center justify-content-between ng-star-inserted"
    >
    <span class="text-xl font-bold">{{ (action | titlecase) + title }}</span>
      <p-button
        icon="pi pi-times"
        (onClick)="hide()"
        styleClass="p-button-rounded p-button-danger p-button-text p-button-raised"
      ></p-button>
    </div>
  </ng-template>


  <div class="mb-4">
    <div class="iconSize"><img src="../../../assets/images/done.svg" /></div>
    <p class="text-typo"> <span class="text-color">{{ moduleName+ ' Records'  }} </span> {{action + 'ed successfully.'}}</p>
  </div>

  <!-- <div class="images-section">
    <ul>
      <li
        class="flex justify-content-between p-1"
        *ngFor="let file of isDetail ? chips : chips?.slice(1)"
      >
        <div (click)="imageView(file)" class="cursor-pointer fileNameText">
          {{ file.file_name ? file.file_name : file.name }}
        </div>
        <div *ngIf="!isDetail">
          <i class="cursor-pointer pi pi-times" (click)="imageRemove(file)"></i>
        </div>
      </li>
    </ul>
  </div> -->
</p-dialog>
