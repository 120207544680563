<div class="col-12 md:col-12 lg:col-12 xl:col-12" [formGroup]="form">
  <div class="flex flex-row justify-content-between">
    <div class="text-left flex flex-row" style="width: 41dvw">
      <p class="text-control-label mb-2 mr-1">
        {{ labelText }} Unassociated
      </p>
      <i class="pi pi-question-circle mt-1 tooltip-style" [tooltipStyleClass]="'tooltipinfo wd-300'" pTooltip="Select checkboxes to view the selected {{labelText.toLowerCase()}} under the {{labelText.toLowerCase()}} associated box" tooltipPosition="top"></i>
    </div>
    <div class="text-left flex flex-row" style="width: 40dvw">
      <p class="text-control-label mb-2 mr-3">
        {{ labelText }} Associated<sup *ngIf="Required">*</sup>
      </p>
      <i class="pi pi-question-circle mt-1" [tooltipStyleClass]="'tooltipinfo wd-250'"  pTooltip="Drag and drop to rearrange the sequencing" tooltipPosition="top"></i>
    </div>
  </div>
  <div
    class="actions-wrapper flex flex-row gap-2 justify-content-between"
    style="min-width: 32dvw !important"
  >
    <div
      class="flex flex-column actions-box"
      style="min-width: 39dvw !important"
    >
      <div
        class="search-box-container"
        style="border-bottom: 1px solid #e0e0e0"
      >
        <div
          style="padding-left: 3.2%; padding-top: 2.5%; padding-bottom: 2.5%"
        >
          <ng-container>
            <span class="p-input-icon-left search_bar">
              <i class="pi i-search"></i>
              <input
                type="text"
                [ngStyle]="{ width: '35.8dvw' }"
                pInputText
                placeholder="Search"
                (input)="filterAssociations($event)"
              />
            </span>
          </ng-container>
        </div>
      </div>
      <div class="actions-content">
        <div
          *ngFor="let action of filtered_associations; let ind = index"
          style="gap: 10px; flex-grow: 1"
          class="flex flex-row col-6"
        >
          <ng-container *ngIf="action?.name?.length">
            <div class="flex justify-content-between w-full pl-5 action-panel">
              <p-checkbox
                class="flex flex-column justify-content-center" 
                [binary]="true"
                (onChange)="onCheckboxChange(ind)"
              ></p-checkbox>
              <span class="flex-grow-1 pl-5 flex flex-column justify-content-center">{{ action.name }}</span>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div
      class="flex flex-column actions-box"
      style="min-width: 39dvw !important"
    >
      <div
        *ngFor="let action of dropped_associations; let ind = index"
        class="flex flex-row col-6"
        (onDrop)="drop($event, ind)"
        (onDragStart)="dragStart(action, ind)"
        (onDragEnd)="dragEnd()"
        (onDragEnter)="onDragEnter($event, ind)"
        pDraggable="action"
        pDroppable="action"
        [ngClass]="{ 'drag-over': isDraggingOver && dragOverIndex === ind }"
      >
        <div>
          <ng-container *ngIf="action?.name?.length">
            <div class="flex justify-content-between w-full pl-5 action-panel"
            >
              <p-button
                class="flex flex-column justify-content-center" 
                icon="pi pi-times"
                styleClass="p-button-rounded remove-action"
                (onClick)="addToOrginals(ind, action)"
              ></p-button>
              <span class="flex-grow-1 pl-5 flex flex-column justify-content-center">{{ action.name }}</span>
              <i class="pi ic-double-bar"></i>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
