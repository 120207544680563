import { Component } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'define-margin',
  templateUrl: './define-margin.component.html',
  styleUrls: ['./define-margin.component.scss']
})
export class DefineMarginComponent {

  margin: any;
  show_MarginDialog:boolean=false;
  dialog_header:string = "Define Margin";

  constructor(public dialogService: DialogService, private messageService: MessageService) { }

  submit_file(){
    console.log('margin', this.margin);
    this.show_MarginDialog = false;
  }
  show(){
    this.show_MarginDialog = true;
  }

  hide(){
    this.show_MarginDialog = false;
  }

}
