import { Component, ViewChild, EventEmitter, Output } from '@angular/core';
import { ACVGridComponent } from '../acv-grid/acv-grid.component';

@Component({
  selector: 'create-sub-order-popup',
  templateUrl: './create-sub-order-popup.component.html',
  styleUrls: ['./create-sub-order-popup.component.scss']
})
export class CreateSubOrderPopupComponent {

  show_Dialog:boolean = false;
  line_itemsColumns: any[];
  line_items:any[];

  @ViewChild('grid_Ref') grid_Ref:ACVGridComponent;
  
  @Output() onSubmit: EventEmitter<any> = new EventEmitter(); 

  show() {
    this.show_Dialog = true;
  }

  hide() {
    this.grid_Ref.clearSelection();
    this.show_Dialog = false;
  }

  submit(){
    this.hide();
    this.onSubmit.emit('');
  }
}
