import { Component, Input } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})
export class NotesComponent {

  margin: any;
  show_NotesDialog:boolean=false;
  tracking_info:boolean=false;
  dialog_header:string = "Item notes";
  notes_data:any[] = [];
  grid_title:any[] = [];
  grid_data:any[] = [];

  @Input('Title') set _Title(value: any){
    this.dialog_header = (value) ? value : this.dialog_header;
  }

  @Input('Data') set _Data(value: any){
    this.notes_data = (value) ? value : [];
  }

  @Input('GridTitle') set _GridTitle(value: any){
    this.grid_title = (value) ? value : [];
  }

  @Input('GridData') set _GridData(value: any){
    this.grid_data = (value) ? value : [];
  }

  @Input('tracking_info') set _tracking_info(value: any){
    this.tracking_info = (value) ? value : false;
  }

  constructor(public dialogService: DialogService, private messageService: MessageService) {}

  show(){
    this.show_NotesDialog = true;
    
  }

  hide(){
    this.show_NotesDialog = false;
  }

  cellClicked(event: any){
    console.log(event);
  }
}
