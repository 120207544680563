<div class="h-30rem w-12 flex flex-column justify-content-center login-form mt-8">
  <form [formGroup]="loginForm" (submit)="handleSubmit()">
    <h3 class="font-poppin text-align-center fw-500 fs-30 mt-0 mb-0">
      Login to your account
    </h3>
    <p class="font-poppin text-align-center fw-400 fs-15 mt-2 mb-0">
      Welcome back! Please enter your details
    </p>
    <div class="flex flex-column justify-content-start flex-wrap mt-4 input-div">
      <div class="relative mt-6">
        <acv-text-input [form]="loginForm" ControlName="email" [IsSubmited]="
            (!loginForm.controls['email'].valid &&
              submitted &&
              !resp?.error &&
              loginForm.controls['email'].value) ||
            (resp && resp?.error.data.error !== 'Invalid Credentials' && loginForm.controls['email'].value)
          " [ControlLabelText]="'Email/Username'" [PlaceHolderText]="'Enter Email'" [Required]="false"
          [ShowWordCount]="false"></acv-text-input>
        <div class="absolute" style="width: 150px !important">
          <p class="control_errorMsg font-poppin fw-400 fs-12 mt-0 mb-0 text-color-red1" *ngIf="
              !loginForm.controls['email'].valid && submitted && !resp?.error
            ">
            Please enter your email.
          </p>
          <p style="width: 100pc;" class="absolute control_errorMsg font-poppin fw-400 fs-12 mt-0 mb-0 text-color-red1" *ngIf="
              resp &&
              resp?.error &&
              loginForm.controls['email'].value &&
              resp?.error.data.error !== 'Invalid Credentials'
            ">
            {{ resp?.error.data.error }}
          </p>
        </div>
      </div>

      <div class="relative mt-4">
        <acv-password-input [form]="loginForm" ControlName="password" [ControlLabelText]="'Password'"
          [PlaceHolderText]="'Enter Password'" [Required]="false" [ShowWordCount]="false" (iconClick)="hide = !hide"
          [IconClass]="hide ? 'pi-eye-slash' : 'pi-eye'" [IconPosition]="'right'" [IsSubmited]="
            (!loginForm.controls['password'].valid &&
              submitted &&
              !resp?.error &&
              loginForm.controls['password'].value) ||
            (resp && resp?.error.data.error !== 'Incorrect email address' && loginForm.controls['password'].value)
          ">
        </acv-password-input>
        <div class="absolute">
          <p class="control_errorMsg font-poppin fw-400 fs-12 mt-0 text-color-red1" *ngIf="
              !loginForm.controls['password'].valid && submitted && !resp?.error
            ">
            Please enter your password.
          </p>
          <p class="control_errorMsg font-poppin fw-400 fs-12 mt-0 text-color-red1" *ngIf="
              resp &&
              resp?.error &&
              loginForm.controls['password'].value &&
              resp?.error.data.error !== 'Incorrect email address'
            ">
            Incorrect Password
          </p>
        </div>
      </div>
    </div>
    <div class="flex flex-row justify-content-between pt-2 mt-3">
      <div class="flex flex-row justify-content-center">
        <p-checkbox formControlName="remember_me" [binary]="true" inputId="binary"></p-checkbox>
        <label class="font-poppin fw-400 fs-14 pb-1">Remember Me</label>
      </div>
      <div>
        <a routerLink="/forgot-password" style="color: inherit"
          class="font-poppin fs-14 fw-400 line-height-2 text-right no-underline pt-2">Forgot Password?</a>
      </div>
    </div>
    <p-button type="submit" [loading]="IsLoading">
      <div class="button-hover-effect1 absolute"></div>
      <div class="button-hover-effect2 absolute"></div>
      <span class="font-poppin fs-14 fw-600 line-height-2 text-align-center"
        style="padding-left: 39%; position: absolute">Login</span>
    </p-button>

  </form>
</div>