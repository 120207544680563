import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'acv-otp-input',
  templateUrl: './otp-input.component.html',
  styleUrls: ['./otp-input.component.scss']
})
export class OtpInputComponent {
  value:any;
  form_data:any;
  @Input() form: FormGroup;
  @Input() ControlName:string;
  @Input() ControlType:string;
  @Input() Required:boolean;
  @Input() ControlLabelText:string;
  @Input() PlaceHolderText:string = "Enter Here";
  @Input() IconClass:string;
  @Input() IconPosition:string;
  @Input() ClassList:string;
  @Input() ShowWordCount:boolean = true;
  @Input() IsSubmited:boolean = false;

  @ViewChild("textControl") text: ElementRef;

  words: number = 0;

  constructor(){
  }

  ngOnInit(): void {
    this.form_data = this.form.value;
  }

  ngAfterViewInit(): void {
    this.form.controls[this.ControlName].setValue(this.form_data[this.ControlName]);
  }
  
}
