<div class="input-elem" [formGroup]="form">
    <p class="text-control-label mb-2">{{ControlLabelText}} <sup *ngIf="Required">*</sup> </p>
    <span class="flex flex-column" [ngClass]="IconClass && IconPosition == 'left' ? 'p-input-icon-left' : IconClass && IconPosition == 'right' ? 'p-input-icon-right' : ''">
        <i class="pi {{IconClass}}" *ngIf="IconClass"></i>
        <input #textControl 
        [formControlName]="ControlName"
        [type]="ControlType"
        [placeholder]="PlaceHolderText"
        [maxlength]="6"
        [ngClass]="{'p-inputtext-error' : IsSubmited}"
        pInputText [(ngModel)]="value" onkeydown="if(event.key==='.'){event.preventDefault();}"
        oninput="event.target.value = event.target.value.replace(/[^0-9]*/g,'');" />
    </span>
</div>
