import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-detail-section',
  templateUrl: './detail-section.component.html',
  styleUrls: ['./detail-section.component.scss']
})
export class DetailSectionComponent {

   panelClose: boolean = false;
   colleapseable: boolean = false;
   HeaderSeparator: boolean = false;
   detail_header:any;
   detail_section:any;
   content_type:string = "single-view";
   activeTabIndex:number = 0;
   active_TabSection:string = "";
   detail_DisplayData:any[] = [];
   header_title:string = "";
   show_header:boolean = true;
   show_masterOrderInfo:boolean = false;
   TrackingInfo:string = "";
   orderID: any;

   @Input('DetailHeader') set _DetailHeader(value: any){
    this.detail_header = (value) ? value : [];
   }

   @Input('DetailSection') set _DetailSection(value: any){
    this.detail_section = (value) ? value : [];
   }

   @Input('HeaderSeparator') set _HeaderSeparator(value: any){
    this.HeaderSeparator = (value) ? value : false;
   }

   @Input('Colleapseable') set _Colleapseable(value: any){
    this.colleapseable = (value) ? value : false;
   }

   @Input('ContentType') set _ContentType(value: any){
    this.content_type = (value) ? value : "single-view";
   }

   @Input('ShowMasterOrderInfo') set _ShowMasterOrderInfo(value: any){
    this.show_masterOrderInfo = (value) ? value : false;
   }

   @Input('TrackingInfo') set _TrackingInfo(value: any){
    this.TrackingInfo = (value) ? value : "";
   }

   @Output() trackingInfoClicked: EventEmitter<any> = new EventEmitter();
   
   constructor(private route: ActivatedRoute){
    
   }

   ngOnInit(): void {
    this.active_TabSection = this.detail_section[this.activeTabIndex].section;
    this.detail_DisplayData = this.detail_section[this.activeTabIndex].data;
    this.LoadHeader();

    this.orderID = this.route.snapshot.paramMap.get('id');

    if(this.show_masterOrderInfo){
      if(this.orderID == 663006 || this.orderID == 663007 || this.orderID == 663009 || this.orderID == 663013 || this.orderID == 663015){
        this.show_masterOrderInfo = false;
      }
      else{
        this.show_masterOrderInfo = true;
      }
    }
    
   }

   onAfterToggle(){}

   toggleChange(evnt:any){
    this.panelClose = !this.panelClose;
   }

   detailTabChange(ind:number, sec:any){
    this.active_TabSection = sec.section;
    this.activeTabIndex = ind;
    this.detail_DisplayData = this.detail_section[ind].data;
    this.LoadHeader(sec);
   }

   LoadHeader(data?:any){
    if(data){
      this.show_header = data.show_header;
    }

    let header = this.detail_header.find((x:any) => x.section == this.active_TabSection?.toLowerCase());
    
    if(header?.data){
      this.header_title = header?.data[0]?.title;
    }
   }

   ChangeTab(movement:any){
    if(movement == "prev"){
      
      if(this.activeTabIndex > 0){
        let ind = this.activeTabIndex - 1;
        this.detailTabChange(ind, this.detail_section[ind]);
      }
      
    }
    if(movement == "next"){
      if(this.activeTabIndex >= 0 && this.activeTabIndex < (this.detail_DisplayData.length -1)){
        
        let ind = this.activeTabIndex + 1;
        this.detailTabChange(ind, this.detail_section[ind]);
      }
    }
   }

   openTrackingInfo(){
      this.trackingInfoClicked.emit('clicked');
    
  }
   
}
