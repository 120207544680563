import { DOCUMENT } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Output,
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiHttpService } from 'src/app/core/services/apiHttpcall.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss'],
})
export class ForgetPasswordComponent {
  @Output() onSuccess = new EventEmitter<string>();

  domain: string = '';
  IsLoading: boolean = false;
  resp: any = undefined;
  submitted: boolean = false;
  constructor(
    @Inject(DOCUMENT)
    private document: any,
    private router: Router,
    private UILoader: NgxSpinnerService,
    private httpService: ApiHttpService,
    private messageService: MessageService,
    private el: ElementRef
  ) {}

  ngOnInit() {
    // Getting current URL from browser
    // this.domain = this.document.location.hostname;
    // console.log(this.domain, 'url');
  }

  handleInput(event: Event) {
    let input = event.target as HTMLInputElement;
    if (input.value === '') {
      this.resp = undefined;
    }
  }

  forgetPassForm = new FormGroup({
    email: new FormControl(null, [Validators.required]),
  });

  handleSubmit() {
    this.submitted = true;
    this.focusIN();
    if (this.forgetPassForm.get('email')?.valid) {
      const emailPayload = this.forgetPassForm.value;
      // this payload needs to be send on the server.
      console.log(emailPayload);
      this.send_ResetLink(emailPayload);
    } else {
    }
  }
  // Send reset password link
  async send_ResetLink(payload: any) {
    this.resp = undefined;
    this.IsLoading = true;
    this.resp = await this.httpService.post(`/password/forgot`, payload);

    if (this.resp.status == 200) {
      this.IsLoading = false;
      this.onSuccess.emit(payload.email);
    } else {
      this.IsLoading = false;
    }
  }

  focusIN() {
    const invalidControl = this.el.nativeElement.querySelector('input');
    if (invalidControl) {
      invalidControl.focus();
    }
    this.resp = undefined;
  }

  navigateToLogin() {
    this.router.navigate(['/login']);
  }
}
