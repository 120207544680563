<!-- Main Authentication Page -->
<div class="flex flex-row container">
  <!-- left side page -->
  <div class="w-6 h-screen pt-2 flex flex-column justify-content-around align-items-center signin-div">
    <div class="w-11 flex flex-row">
      <img class="align-items-start logo-image" src="assets/images/logo.png" (click)="navigateToLogin()" />
    </div>
    <app-login *ngIf="loginPage"></app-login>
    <app-forget-password *ngIf="forgetPasswordPage" (onSuccess)="handleSuccess($event)"></app-forget-password>
    <app-otp *ngIf="otpPage" (onSuccess)="handleSuccess($event)" [email]="email"></app-otp>
    <reset-password *ngIf="reset_pwd" [email]="email"></reset-password>
    <div class="w-11 flex flex-row justify-content-center">
      <p class="fw-500 fs-14 font-poppin text-color-grey3 line-height-21 text-left mb-0 pt-8 pb-2">&copy; 2024. ACV Logistics</p>
      <!-- <p class="fw-500 fs-14 font-poppin text-color-grey3 line-height-21 text-left mb-0 pt-8">Term of use</p> -->
    </div>
  </div>
  <!-- right side page -->
  <div class="w-6 pt-1 pr-3 image-div" style="height: 99vh;">
    <img class="w-full h-full" src="assets/images/Mask group.svg" />
  </div>
</div>
