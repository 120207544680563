import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'grid-tab',
  templateUrl: './grid-tab.component.html',
  styleUrls: ['./grid-tab.component.scss']
})
export class GridTabComponent implements OnInit {

  grid_tabs:any;
  WithOutCount:boolean=false;
  ShowInfo:boolean=false;
  tab_type:string = "inquiry_grid";
  selectedTabIndex:number = 0;

  @Input('GridTabs') set _GridTabs(value: any) {
    this.grid_tabs = (value) ? value : [];
  }

  @Input('WithOutCount') set _WithOutCount(value: any) {
    this.WithOutCount = (value) ? value : false;
  }

  @Input('ShowInfo') set _ShowInfo(value: any) {
    this.ShowInfo = (value) ? value : false;
  }

  @Input('TabType') set _TabType(value: any) {
    this.tab_type = (value) ? value : 'inquiry_grid';
  }

  @Input('ActiveTabIndex') set _ActiveTabIndex(value: number) {
    if(value){
      this.selectedTabIndex = value;
    }
  }

  @Output() selectedTab: EventEmitter<any> = new EventEmitter();
  @Output() infoClicked: EventEmitter<any> = new EventEmitter();

  constructor(){}

  
  ngOnInit(){

    setTimeout(() => {
      if(this.ShowInfo){
        var esp=document.createElement("span");
        esp.classList.add('i-info')
        document.getElementsByClassName('p-tabview-nav-content')[0].appendChild(esp);
      }


      var icon = document.getElementsByClassName('i-info')[0];
      if(icon){
        icon.addEventListener("click", () => {
           this.infoClick();
        });
      }
    }, 500);
    
  }

  onTabChange(evnt:any){
    this.selectedTabIndex = evnt.index;
    this.selectedTab.emit(evnt);
    if(evnt.index==1 || evnt.index==2){
      var span = document.getElementsByClassName('i-info')[0];
        span?.parentNode?.removeChild(span);
    }else{
      if(this.ShowInfo){
        var esp=document.createElement("span");
        esp.classList.add('i-info')
        document.getElementsByClassName('p-tabview-nav-content')[0].appendChild(esp);

        var icon = document.getElementsByClassName('i-info')[0];
        if(icon){
          icon.addEventListener("click", () => {
             this.infoClick();
          });
        }
      }
    }
  }
  
  infoClick(){
    this.infoClicked.emit('clicked');
  }

  selectTab(tabIndex:number){
    this.selectedTabIndex = (tabIndex < 0) ? 0 : tabIndex;
  }

}


