import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'ACV';
  time: any;

  constructor(private router: Router, private messageService: MessageService,) {}
  
  // temporary for now.
  ngOnInit(): void {
    //this.router.navigate(['login']);
  }

  logout() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('rememberMe');
    localStorage.removeItem('user_permissions');
    this.router.navigate(['login']);
  }

  // @HostListener('document:mousemove')
  // @HostListener('document:keypress')
  // @HostListener('document:click')
  // @HostListener('document:wheel')
  // resetTimer() {
  //   clearTimeout(this.time);
  //   this.time = setTimeout(() => {
  //     let remember = localStorage.getItem('rememberMe');
  //     if(remember === "false" || remember === undefined){
  //       this.messageService.add({
  //         severity: 'error', summary: '',
  //         detail: "Session Expired", key: 'app_notiy'
  //       });
  //       this.logout();
  //     }
  //     // Idle for 15 minutes which is 900000
  //   }, 900000);
  // }
}
