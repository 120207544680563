import { Component, Input } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
interface Change {
  new: string;
  old: string;
}

interface Changes {
  [key: string]: Change;
}

@Component({
  selector: 'app-history-detail',
  templateUrl: './history-detail.component.html',
  styleUrls: ['./history-detail.component.scss']
})
export class HistoryDetailComponent {

  visibility: boolean = false;
  detailsData: any;

  @Input() moduleName: string = '';
  @Input() data: { properties: { changes: Changes } }[] = [];
  

  constructor(public dialogService: DialogService) {}

  ngOnChanges() {
    this.detailsData = this.data;
  }

  show(){
    this.visibility = true;
  }
  hide(){
    this.visibility = false;
  }
}
