<p-dialog [styleClass]="'popup_import'" [draggable]="false" [closable]="false" [style]="data_type == 'panel' ? {'width': '50%'} : {'width': '30%'}" [modal]="true"
    [visible]="show_Dialog">

    <ng-template pTemplate="header">
        <div class="flex wd-100 dialog_header_wrapper align-items-center justify-content-between ng-star-inserted">
            <span class="text-xl font-bold">{{title}}</span>
            <p-button icon="pi pi-times" (onClick)="hide()"
                styleClass="p-button-rounded p-button-danger p-button-text p-button-raised"></p-button>
        </div>
    </ng-template>

    <div class="import_content_wrapper">
        <acv-grid *ngIf="data_type == 'grid'" [GridColumns]="gridColumns" [GridDataSource]="data"
            [AllowSelection]="false" [AllowSorting]="false" [AllowPagination]="false" [AllowColumnReOrdering]="false"
            [ShowLineLevelAction]="false"></acv-grid>

        <ng-container *ngIf="data_type == 'list'">
            <p class="fw-500 fs-14 font-poppin mt-4 mb-4" *ngFor="let item of data">{{item}}</p>
        </ng-container>

        <ng-container *ngIf="data_type == 'panel'">
            <div class="grid m-0 mt-3">
                
                <div *ngFor="let milestone of data" class="col-12 md:col-12 lg:col-12 xl:col-12 p-0 mb-3">
                    <p-panel [toggleable]="true" [collapsed]="milestone === data[0] ? false : true" [className]="'panel-grey'" 
                    [collapseIcon]="'pi pi-chevron-up'" [expandIcon]="'pi pi-chevron-down'">
                        <ng-template pTemplate="header"> 
                            <div class="flex gap-2">
                                <p class="font-poppin fw-500 fs-14 text-color-black2">{{milestone?.name}}</p>
                            </div>
                        </ng-template>
                        
                        <acv-grid [GridColumns]="gridColumns" [GridDataSource]="milestone.actions" [AllowSelection]="false"
                            [AllowSorting]="false" [AllowPagination]="false" [AllowColumnReOrdering]="false"
                            [ShowLineLevelAction]="false"></acv-grid>
                        
                        <!-- <div *ngFor="let action of milestone.actions" class="grid m-0">
                           
                        </div> -->
                    </p-panel>
                </div>
            </div>
        </ng-container>

    </div>
</p-dialog>