import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { DataResolverService } from './core/services/data-resolver.service';
import { AuthPageComponent } from './features/account/auth-page/auth-page.component';
//import { DefaultLayoutComponent } from './core/components/default-layout/default-layout.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  { path: 'login', component: AuthPageComponent },
  { path: 'forgot-password', component: AuthPageComponent },
  { path: '', loadChildren: () => import('./core/core.module').then((m) => m.CoreModule) }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: false,
    preloadingStrategy: PreloadAllModules, scrollPositionRestoration: 'enabled', onSameUrlNavigation: 'ignore' })],
  exports: [RouterModule],
  providers: [DataResolverService]
})
export class AppRoutingModule { }
