<app-history-detail
  #historyDetail_Ref
  [moduleName]="moduleName"
  [data]="historyData"
></app-history-detail>
<p-dialog
  [modal]="true"
  [breakpoints]="{ '960px': '75vw', '640px': '100vw' }"
  [visible]="visibility"
  [draggable]="false"
  [classList]="'filter_dialog'"
  [position]="'right'"
  [resizable]="false"
  [closable]="false"
>
  <ng-template pTemplate="header">
    <div
      class="dialogHeader flex align-items-center justify-content-between wd-100 pl-2"
    >
      <p>HISTORY</p>
      <p-button
        icon="pi pi-times"
        (onClick)="hide()"
        styleClass="p-button-text p-button-rounded"
      ></p-button>
    </div>
  </ng-template>

  <p-scrollPanel [className]="'filter_scrollPanel'">
    <ng-container>
      <div class="timeline-wrapper">
        <div class="timeline">
          <div class="container" *ngFor="let content of events">
            <div class="content">
              <div class="flex flex-column">
                <div
                  class="flex align-items-center justify-content-between gap-4"
                >
                  <label
                    *ngIf="content?.properties"
                    class="mb-1 flex justify-content-start gap-1"
                  >
                    <div class="custom-avatar">
                      <label>{{
                        content?.user_name?.substring(0, 1).toUpperCase()
                      }}</label>
                    </div>
                    <span class="maintitle_font mt-1"
                      >{{ content.user_name }} -
                    </span>
                    <span class="maintitleDate_font mt-1">{{
                      content.updated_at | date : "MMM d, y HH:mm:ss"
                    }}</span>
                                        
                  </label>
                </div>
                <div *ngIf="content?.properties?.Attributes" class="flex justify-content-center">
                  <span class="maintitle_font">
                    ({{ content?.log_name }} created)
                  </span>
                </div>
                <div
                  style="padding-left: 30px"
                  *ngIf="content?.properties?.Changes"
                >
                  <div
                    class="changes-list"
                    *ngFor="let change of content.properties.Changes | keyvalue"
                  >
                    <div
                      class="change-item flex align-items-center justify-content-start gap-1"
                    >
                      <div class="bullet"></div>
                      <p
                        class="custom-text-width font-poppin fw-400 fs-12 text-color-custom"
                        [pTooltip]="getChangeValues(change)?.old"
                        tooltipPosition="left"
                      >
                        {{ getChangeValues(change)?.old }}
                      </p>
                      <div>
                        <img
                          src="../../../assets/images/arrow-right.svg"
                          class="mt-1"
                          alt="arrow"
                          width="18px"
                          height="18px"
                        />
                      </div>
                      <p
                        class="custom-text-width font-poppin fw-500 fs-12 text-color-primary"
                        [pTooltip]="getChangeValues(change)?.new"
                        tooltipPosition="left"
                      >
                        {{ getChangeValues(change)?.new }}
                      </p>
                      (
                      <p
                        class="custom-text-width font-poppin text-right fw-400 fs-12 custom-style text-color-custom"
                        pTooltip="{{ change.key }}"
                        tooltipPosition="left"
                      >
                        {{ change.key }}
                      </p>
                      )
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div
              class="flex justify-content-center align-items-center center-align-text absolute w-full"
              *ngIf="!hasUpdatedEvent()"
            >
              No Logs Found.
            </div> -->
          </div>
          <div
            class="flex justify-content-center align-items-center center-align-text absolute w-full"
            *ngIf="!events?.length"
          >
            No Logs Found.
          </div>
        </div>
      </div>
    </ng-container>

    <!-- </div> -->
  </p-scrollPanel>

  <!-- <ng-template pTemplate="footer">
        <div class="filter_dialogFooter flex align-items-center justify-content-end wd-100">
            <p-button type="button" label="Close" class="p-button-text elevated-btn primary" (onClick)="hide()"></p-button>
        </div>
    </ng-template> -->
</p-dialog>
