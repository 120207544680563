<div class="h-30rem w-12 flex flex-column justify-content-center login-form mt-8">
    <form *ngIf="!resetSuccess" [formGroup]="resetPasswordForm">
        <h3 class="font-poppin text-center fw-500 fs-30 mt-0 mb-0">Reset Password</h3>
        <p class="font-poppin text-center fw-400 fs-15 mt-2 mb-4">Please enter new password</p>
        <div class="flex flex-column justify-content-start flex-wrap mt-6 input-div">
            <!-- <p class="text-left font-poppin fw-400 fs-15 mt-0 mb-2">New Password</p>
            <span class="p-input-icon-right" (click)="op.toggle($event)">
                <i class="pi" [ngClass]="{ 'pi-eye-slash': hide, 'pi-eye': !hide }" (click)="hide = !hide"></i>
                <input #passwordInput class="w-full"
                    [ngClass]="{'p-inputtext-error': (!resetPasswordForm.controls['password'].valid && !resp?.error && resetPasswordForm.controls['password'].value) || (resp && passMatch && resetPasswordForm.controls['password'].value) || emptyFieldPass}"
                    formControlName="password" name="password" placeholder="Enter New Password"
                    [type]="hide ? 'password' : 'text'" pInputText (input)="checkPasswordRequirements()" />
                <p-overlayPanel #op [style]="{'width': overlayWidth}">
                    <ng-template pTemplate>
                        <ul class="mt-0" style="line-height: 1.5">
                            <span class="font-poppin fw-400 fs-14 line-height-21" style="color: #8E8E8E;">Password
                                Strength:</span>
                            <ul class="pr-6 mt-2"><i class="pr-2 pi"
                                    [ngClass]="{ 'pi-times': !passwordRequirements.length, 'pi-check': passwordRequirements.length }"
                                    [style.color]="passwordRequirements.length ? 'var(--success-green)' : 'var(--text-danger)'"></i>
                                <span class="fs-12 font-poppin fw-400"
                                    [style.color]="passwordRequirements.length ? 'var(--success-green)' : 'var(--text-danger)'">At
                                    least 8 characters long</span></ul>
                            <ul class="pr-6"><i class="pr-2 pi fs-12"
                                    [ngClass]="{ 'pi-times': !passwordRequirements.specialChar, 'pi-check': passwordRequirements.specialChar }"
                                    [style.color]="passwordRequirements.specialChar ? 'var(--success-green)' : 'var(--text-danger)'"></i>
                                <span class="fs-12 font-poppin fw-400"
                                    [style.color]="passwordRequirements.specialChar ? 'var(--success-green)' : 'var(--text-danger)'">At
                                    least 1 special character</span></ul>
                            <ul class="pr-6"><i class="pr-2 pi fs-12"
                                    [ngClass]="{ 'pi-times': !passwordRequirements.number, 'pi-check': passwordRequirements.number }"
                                    [style.color]="passwordRequirements.number ? 'var(--success-green)' : 'var(--text-danger)'"></i>
                                <span class="fs-12 font-poppin fw-400"
                                    [style.color]="passwordRequirements.number ? 'var(--success-green)' : 'var(--text-danger)'">At
                                    least one number</span></ul>
                            <ul class="pr-6"><i class="pr-2 pi fs-12"
                                    [ngClass]="{ 'pi-times': !passwordRequirements.capitalLetter, 'pi-check': passwordRequirements.capitalLetter }"
                                    [style.color]="passwordRequirements.capitalLetter ? 'var(--success-green)' : 'var(--text-danger)'"></i>
                                <span class="fs-12 font-poppin fw-400"
                                    [style.color]="passwordRequirements.capitalLetter ? 'var(--success-green)' : 'var(--text-danger)'">At
                                    least one capital letter</span></ul>
                        </ul>
                    </ng-template>
                </p-overlayPanel>
            </span>

            <p class="absolute control_errorMsg font-poppin fw-400 fs-12 mt-0 text-color-red1" style="top: 53.2%;"
                *ngIf="emptyFieldPass">
                Please enter the New Password.
            </p>
            <p class="absolute control_errorMsg font-poppin fw-400 fs-12 mt-0 text-color-red1" style="top: 53.2%;"
                *ngIf="resp && passMatch && resetPasswordForm.controls['password'].value">
                Password don't meet the requirements.
            </p> -->
            <acv-password-input
                [form]="resetPasswordForm"
                ControlName="password"
                [ControlLabelText]="'Password'"
                [PlaceHolderText]="'Enter Password'"
                [overlay]="true"
                [ShowWordCount]="false"
                (iconClick)="hide = !hide"
                [IconClass]="hide ? 'pi-eye-slash' : 'pi-eye'"
                [IconPosition]="'right'"
                [IsSubmited]="(!resetPasswordForm.controls['password'].valid && !resp?.error && resetPasswordForm.controls['password'].value) || (resp && passMatch && resetPasswordForm.controls['password'].value) || emptyFieldPass"
              >
              </acv-password-input>
            <div class="relative mt-4">
                <acv-password-input [form]="resetPasswordForm" ControlName="password_confirmation"
                    [ControlLabelText]="'Confirm Password'" [PlaceHolderText]="'Enter Confirm Password'"
                    [IsSubmited]="(resp && resp?.error.message == 'The password field confirmation does not match.' && resetPasswordForm.controls['password_confirmation'].value) || emptyFieldConfirm"
                    [Required]="false" [ShowWordCount]="false" (iconClick)="hideConfirm = !hideConfirm"
                    [IconClass]="hideConfirm ? 'pi-eye-slash' : 'pi-eye'" [IconPosition]="'right'">
                </acv-password-input>
                <p class="absolute control_errorMsg font-poppin fw-400 fs-12 mt-0 text-color-red1"
                    *ngIf="emptyFieldConfirm">
                    Please enter the Confirm Password.
                </p>
                <p class="absolute control_errorMsg font-poppin fw-400 fs-12 mt-0 text-color-red1"
                    *ngIf="resp && resp?.error.message == 'The password field confirmation does not match.' && resetPasswordForm.controls['password_confirmation'].value">
                    {{resp?.error.message}}
                </p>
            </div>
        </div>
        <p-button class="relative" (click)="handleSubmit()">
            <div class="button-hover-effect1 absolute"></div>
            <div class="button-hover-effect2 absolute"></div>
            <span class="font-poppin text-center fw-600 fs-14 line-height-21 absolute"
                style="padding-left: 40%;">Reset</span>
        </p-button>
    </form>
    <div *ngIf="resetSuccess" class="flex flex-column justify-content-center align-items-center">
        <h3 class="font-poppin text-center fw-500 fs-30 mt-0 mb-0 line-height-3 text-color-black">Password Reset</h3>
        <p class="font-poppin text-center fw-400 fs-15 mt-0 mb-0 line-height-2 text-color-grey4">Your Password is reset.
            Please login.</p>
        <p-button class="relative w-full" (click)="navigateToLogin()">
            <div class="button-hover-effect1 absolute"></div>
            <div class="button-hover-effect2 absolute"></div>
            <span class="font-poppin text-center fw-600 fs-14 line-height-21 absolute"
                style="padding-left: 40%;">Login</span>
        </p-button>
    </div>