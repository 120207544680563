import { Component, Input } from '@angular/core';

@Component({
  selector: 'detail-view',
  templateUrl: './detail-view.component.html',
  styleUrls: ['./detail-view.component.scss']
})
export class DetailViewComponent {

  Is_defaultLayout:boolean = false;

  @Input('DefaultLayout') set _DefaultLayout(value: any) {

    this.Is_defaultLayout = (value) ? value : false;
  }

}
