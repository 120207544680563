import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiHttpService } from 'src/app/core/services/apiHttpcall.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent {
  @ViewChild('passwordInput') passwordInput: ElementRef;
  @Output() onSuccess = new EventEmitter<string>();
  @Input() email: string;
  IsLoading: boolean = false;
  hide: boolean = true;
  hideConfirm: boolean = true;
  resp: any = undefined;
  overlayWidth: string = '0px';
  resetSuccess: boolean = false;
  passValidation: boolean = false;
  

  constructor(
    private router: Router,
    private httpService: ApiHttpService,
    private el: ElementRef,
    private cdr: ChangeDetectorRef,
    private messageService: MessageService
  ) {}

  submitted: boolean = false;
  loginError: string = '';
  passMatch: boolean = false;
  emptyFieldPass: boolean = false;
  emptyFieldConfirm: boolean = false;

  resetPasswordForm = new FormGroup({
    password: new FormControl<any>(null, [Validators.required]),
    password_confirmation: new FormControl(null, [Validators.required]),
    email: new FormControl<any>(null),
  });

  ngAfterViewInit() {
    const inputWidth = this.passwordInput.nativeElement.offsetWidth;
    this.overlayWidth = `${inputWidth}px`;
    this.cdr.detectChanges();
  }

  passwordRequirements: {
    length: boolean;
    specialChar: boolean;
    number: boolean;
    capitalLetter: boolean;
  } = {
    length: false,
    specialChar: false,
    number: false,
    capitalLetter: false,
  };

  checkPasswordRequirements() {
    this.emptyFieldPass = false;
    this.emptyFieldConfirm = false;
    let password: string | null = this.resetPasswordForm.get('password')?.value;

    if (password) {
      this.passwordRequirements.length = password.length >= 8;
      this.passwordRequirements.specialChar = /[!@#$%^&*(),.?":{}|<>]/g.test(
        password
      );
      this.passwordRequirements.number = /\d/.test(password);
      this.passwordRequirements.capitalLetter = /[A-Z]/.test(password);
      this.passValidation = this.passwordRequirements.length && this.passwordRequirements.specialChar && this.passwordRequirements.number && this.passwordRequirements.capitalLetter;
    } else {
      this.passwordRequirements.length = false;
      this.passwordRequirements.specialChar = false;
      this.passwordRequirements.number = false;
      this.passwordRequirements.capitalLetter = false;
      this.passValidation = false;
      this.resp = undefined;
    }
  }

  async handleSubmit() {
    this.submitted = true;
    this.focusIN();
    if (
      this.resetPasswordForm.get('password')?.valid &&
      this.resetPasswordForm.get('password_confirmation')?.valid
    ) {
      this.IsLoading = true;
      let payload = this.resetPasswordForm.value;
      payload.email = this.email;
      this.resp = await this.httpService.post(
        '/password/reset',
        this.resetPasswordForm.value
      );
      if (this.resp.status == 200) {
        this.resetSuccess = true;
      } else {
        this.passMatch = this.resp.error.message == 'The password field confirmation does not match.' ? false : true;
        this.IsLoading = false;
        this.submitted = false;
        this.resetSuccess = false;
        this.emptyFieldPass = false;
        this.emptyFieldConfirm = false;
      }
    }
    else{
      if(!this.resetPasswordForm.get('password')?.value){
        this.emptyFieldPass = true;
      }
      if(!this.resetPasswordForm.get('password_confirmation')?.value){
        this.emptyFieldConfirm = true;
      }
    }
  }

  navigateToLogin() {
    this.router.navigate(['/login']);
  }

  focusIN() {
    const invalidControl = this.el.nativeElement.querySelector('input');
    if (invalidControl) {
      invalidControl.focus();
    }
    this.resp = undefined;
  }
}
