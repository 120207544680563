<div class="input-elem relative" [formGroup]="form">
    <p class="text-control-label mb-2">{{ControlLabelText}} <sup *ngIf="Required">*</sup> </p>
    <span class="flex flex-column" (click)="toggleOverlay($event)" [ngClass]="IconClass && IconPosition == 'left' ? 'p-input-icon-left' : IconClass && IconPosition == 'right' ? 'p-input-icon-right' : ''">
        <!-- <i class="cursor-pointer pi {{IconClass}}" *ngIf="IconClass" (click)="showPassword()"></i> -->
        <i class="cursor-pointer font-poppin" style="font-style: normal; font-size: 12px !important; font-weight: 500; color: #4F4F4F; user-select: none;" *ngIf="IconClass" (click)="showPassword()">{{IconClass === 'pi-eye' ? 'Hide' : 'Show'}}</i>
        <input #passwordInput 
        [formControlName]="ControlName"
        [type]="ControlType"
        [placeholder]="PlaceHolderText"
        [maxlength]="50"
        [ngClass]="{'input-error' : !form.controls[ControlName].valid && IsSubmited} || form.controls[ControlName]?.errors?.['incorrect']"
        pInputText [(ngModel)]="value" (keydown)="wordCounter()" (input)="checkPasswordRequirements()"/>
        <p-overlayPanel *ngIf="overlay" #op [style]="{'width': overlayWidth}">
            <ng-template pTemplate>
                <ul class="mt-0" style="line-height: 1.5">
                    <span class="font-poppin fw-400 fs-14 line-height-21" style="color: #8E8E8E;">Password
                        Strength:</span>
                    <ul class="pr-6 mt-2"><i class="pr-2 pi"
                            [ngClass]="{ 'pi-times': !passwordRequirements.length, 'pi-check': passwordRequirements.length }"
                            [style.color]="passwordRequirements.length ? 'var(--success-green)' : 'var(--text-danger)'"></i>
                        <span class="fs-12 font-poppin fw-400"
                            [style.color]="passwordRequirements.length ? 'var(--success-green)' : 'var(--text-danger)'">At
                            least 8 characters long</span></ul>
                    <ul class="pr-6"><i class="pr-2 pi fs-12"
                            [ngClass]="{ 'pi-times': !passwordRequirements.specialChar, 'pi-check': passwordRequirements.specialChar }"
                            [style.color]="passwordRequirements.specialChar ? 'var(--success-green)' : 'var(--text-danger)'"></i>
                        <span class="fs-12 font-poppin fw-400"
                            [style.color]="passwordRequirements.specialChar ? 'var(--success-green)' : 'var(--text-danger)'">At
                            least 1 special character</span></ul>
                    <ul class="pr-6"><i class="pr-2 pi fs-12"
                            [ngClass]="{ 'pi-times': !passwordRequirements.number, 'pi-check': passwordRequirements.number }"
                            [style.color]="passwordRequirements.number ? 'var(--success-green)' : 'var(--text-danger)'"></i>
                        <span class="fs-12 font-poppin fw-400"
                            [style.color]="passwordRequirements.number ? 'var(--success-green)' : 'var(--text-danger)'">At
                            least one number</span></ul>
                    <ul class="pr-6"><i class="pr-2 pi fs-12"
                            [ngClass]="{ 'pi-times': !passwordRequirements.capitalLetter, 'pi-check': passwordRequirements.capitalLetter }"
                            [style.color]="passwordRequirements.capitalLetter ? 'var(--success-green)' : 'var(--text-danger)'"></i>
                        <span class="fs-12 font-poppin fw-400"
                            [style.color]="passwordRequirements.capitalLetter ? 'var(--success-green)' : 'var(--text-danger)'">At
                            least one capital letter</span></ul>
                </ul>
            </ng-template>
        </p-overlayPanel>
    </span>
    <p class="absolute control_errorMsg font-poppin fw-400 fs-12 mt-0 text-color-red1"
                *ngIf="emptyFieldPass">
                Please enter the New Password.
            </p>
            <p class="absolute control_errorMsg font-poppin fw-400 fs-12 mt-0 text-color-red1"
                *ngIf="!passMatch && form.controls['password']?.value && overlay">
                Password don't meet the requirements.
            </p>
    <div class="mt-1 text-right" [ngClass]="{'flex justify-content-between' : !form.controls[ControlName].valid && IsSubmited}">
        <p class="fs-10 fw-400 font-poppin text-color-red" *ngIf="!form.controls[ControlName].valid && IsSubmited">
            <span *ngIf="form.controls[ControlName]?.errors?.['required']">Please fill this required field</span>
            <span *ngIf="form.controls[ControlName]?.errors?.['incorrect']">{{form.controls[ControlName]?.errors?.['message']}}</span>
            </p>
        <p id="word-count" *ngIf="ShowWordCount" [ngClass]="words > 50 ? 'text-danger' : ''">{{words}}/50 Characters</p>
    </div>
    <!-- <div class="text-right mt-1" *ngIf="ShowWordCount">
        <p id="word-count" [ngClass]="words > 50 ? 'text-danger' : ''">{{words}}/50 Characters</p>
    </div> -->
</div>
