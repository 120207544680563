import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { ProfileService } from './profile.service';

/** Pass untouched request through to the next request handler. */
@Injectable()
export class Interceptor implements HttpInterceptor {
constructor(public config:ProfileService){
}
  // intercept(req: HttpRequest<any>, next: HttpHandler):
  //   Observable<HttpEvent<any>> {
  //   return next.handle(req);
  // }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    // Get the auth token from the service.
    // let access_token = localStorage.getItem("access_token");
    // const authToken = localStorage.getItem('access_token');
    this.config.loginToken = localStorage.getItem('access_token') || '';
    const authToken = this.config.loginToken ? 'Bearer ' + this.config.loginToken : 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC8xODQuNzIuMTQyLjE0M1wvYXV0aF9zZXJ2aWNlXC9hcGlcL2F1dGhcL2xvZ2luIiwiaWF0IjoxNjU1MjExNzAyLCJleHAiOjE2NTUyMTUzMDIsIm5iZiI6MTY1NTIxMTcwMiwianRpIjoiNVhUd1BzWVZrc0NHa2NXNyIsInN1YiI6MSwicHJ2IjoiMjNiZDVjODk0OWY2MDBhZGIzOWU3MDFjNDAwODcyZGI3YTU5NzZmNyIsImVtYWlsIjoiY2xpZW50QHNodGlrcy5jb20iLCJjbGllbnQiOjEsInNlcnZpY2VfcHJvdmlkZXIiOm51bGx9.5kE-nFB77VrtzkPaBtJkQyN8Bt9UCHlnc6_Tk5Ncots';

    // Clone the request and replace the original headers with
    // cloned headers, updated with the authorization.
    const authReq = req.clone({
      headers: req.headers.set('Authorization', authToken)
    });

    // send cloned request with header to the next handler.
    return next.handle(authReq);
  }


}
