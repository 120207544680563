import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'acv-multiselect',
  templateUrl: './multiselect-dropdown.component.html',
  styleUrls: ['./multiselect-dropdown.component.scss']
})
export class MultiselectDropdownComponent {

  @Input() form: FormGroup;
  @Input() ControlName: string;
  @Input() Options_List:any[];
  @Input() OptionLabel:string = "name";
  @Input() OptionValue:string = "id";
  @Input() Required:boolean;
  @Input() ControlLabelText:string;
  @Input() PlaceHolderText:string = "Enter Here";
  @Input() Filter:boolean = false;
  @Input() selectedItems:any[] = [];
  @Input() IsSubmited:boolean = false;

  @Input('selectedItems') set _SelectedItems(value: any) {
    console.log("From child", value);
    
    if(value && value.length > 0){
      setTimeout(() => {
        this.selectedItems = value;

      }, 2000);
    }
  }
}
