import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-popup-chips',
  templateUrl: './popup-chips.component.html',
  styleUrls: ['./popup-chips.component.scss'],
})
export class PopupChipsComponent {
  title: string = 'Uploaded Files';
  show_Dialog: boolean = false;
  @Input() chips: any[] = [];
  @Input() isDetail: boolean = false;
  @Output() removeImage: EventEmitter<any> = new EventEmitter();
  @Output() viewImage: EventEmitter<any> = new EventEmitter();

  constructor(public dialogService: DialogService) {}

  imageRemove(file: any) {
    this.removeImage.emit(file);
    if (this.chips.length <= 2) {
      this.hide();
    }
  }
  imageView(file: any) {
    this.viewImage.emit(file);
  }
  show() {
    this.show_Dialog = true;
  }

  hide() {
    this.show_Dialog = false;
  }
}
