<div
  class="h-30rem w-12 flex flex-column justify-content-center login-form mt-8"
>
  <form [formGroup]="otpForm">
    <h3 class="font-poppin text-center fw-500 fs-30 mt-0 mb-0">Verify OTP</h3>
    <p class="font-poppin text-center fw-400 fs-15 mt-2 mb-2">
      OTP is send to {{ email }}. Please check <br />your spam folder also.
    </p>
    <div
      class="flex flex-column justify-content-start flex-wrap mt-4 mb-1 input-div"
    >
      <div class="relative">
        <acv-otp-input
          (input)="handleInput($event)"
          [form]="otpForm"
          ControlName="otp"
          [ControlLabelText]="'OTP'"
          [PlaceHolderText]="'Enter OTP'"
          [Required]="false"
          [ShowWordCount]="false"
          [IsSubmited]="
            (!otpForm.controls['otp'].valid &&
              submitted &&
              !resp?.error &&
              otpForm.controls['otp'].value) ||
            (resp && resp?.error && otpForm.controls['otp'].value) || (emptyOTP)
          "
        ></acv-otp-input>
        <p
          class="absolute control_errorMsg font-poppin fw-400 fs-12 mt-0 mb-0 text-color-red1"
          *ngIf="
           emptyOTP
          "
        >
          Please enter the OTP.
        </p>
        <p
          class="absolute control_errorMsg font-poppin fw-400 fs-12 mt-0 mb-0 text-color-red1"
          *ngIf="resp && resp?.error && otpForm.controls['otp'].value"
        >
          {{ resp?.error.message }}
        </p>
      </div>
    </div>
    <div class="resend-text flex flex-row justify-content-between mt-3">
      <p class="font-poppin fw-400 fs-14">
        Time Remaining:
        <span class="resend-link fs-14 fw-500 font-poppin">
          {{ displayTime }}
        </span>
      </p>
      <span
        class="resend-link fs-14 fw-400 font-poppin"
        style="cursor: pointer"
        (click)="resendOtp()"
        [style.pointer-events]="resendEnabled ? 'auto' : 'none'"
        [style.color]="resendEnabled ? 'var(--primary-bg)' : '#898F9B'"
      >
        Resend OTP
      </span>
    </div>
    <p-button class="relative" (click)="handleSubmit()">
      <div class="button-hover-effect1 absolute"></div>
      <div class="button-hover-effect2 absolute"></div>
      <span
        class="font-poppin text-center fw-600 fs-14 line-height-21 absolute"
        style="padding-left: 40%"
        >Verify</span
      >
    </p-button>
  </form>
</div>
