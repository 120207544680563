<div class="grid_wrapper">
    <acv-grid 
        [GridColumns]="gridColumns" 
        [GridDataSource]="gridDataSource"
        [AllowSelection]="true"
        [AllowPagination]="false"
        [AllowSorting]="false"
        [AllowColumnReOrdering]="false"
        [ShowLineLevelAction]="false"
        (_SelectionChange)="row_Selection($event)"></acv-grid>
</div>
<div class="popup-footer-detail">
    <p>John is requesting profit margin over ride approval for customer 100 with item(s) 038X302X1600-G</p>
</div>
<div class="popup-footer-buttons">
    <p-button type="button" label="Approve" class="p-button-text elevated-btn primary mr-2" (click)="approve()"></p-button>
    <p-button type="button" label="Cancel" class="p-button-text elevated-btn secondary" [style]="{'color':'black'}" (click)="cancel()"></p-button>
</div>
