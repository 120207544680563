import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-upload',
  templateUrl: './upload-popup.component.html',
  styleUrls: ['./upload-popup.component.scss'],
})
export class UploadPopupComponent {
  value: number = 0;
  allowed_formats: string[] = ['png', 'jpg', 'jpeg', 'pdf'];
  maxFileSize: number;
  uploadedFiles: any[] = [];
  file: string[];
  file_name: string = '';
  file_extension: string = '';
  show_uploadDialog: boolean = false;
  dialog_header: string = 'Upload Documents';
  uploadError_Visible: boolean = false;
  genericError_Visible: boolean = false;
  uploadError_Message: string = '';
  Is_ValidFile: boolean = false;
  interval: any;

  @ViewChild('fubauto') fileUpload: any;
  filesForUpdate: any;

  @Input('MaxFileSize') set _MaxFileSize(value: any) {
    this.maxFileSize = value ? value : 5242880;
  }
  @Output() Submit_Clicked: EventEmitter<any> = new EventEmitter();
  @Output() viewImage: EventEmitter<any> = new EventEmitter();


  constructor(public dialogService: DialogService) {}

  onFileDrop(evnt: any) {
    const files = evnt || [];
    this.filesForUpdate = files;
    const allFiles = [...files];
    const allowedFormats = this.allowed_formats;
    const maxSize = this.maxFileSize;

    let totalFileSize = this.uploadedFiles.reduce(
      (acc, file) => acc + file.size,
      0
    );

    allFiles.forEach((file) => {
      const fileExtension = file?.file.name.split('.').pop().toLowerCase();
      if (allowedFormats.includes(fileExtension)) {
        if (totalFileSize + file?.file.size <= maxSize) {
          const isDuplicate = this.uploadedFiles.some(
            (existingFile) =>
              existingFile.name === file?.file.name &&
              existingFile.size === file?.file.size &&
              existingFile.lastModified === file?.file.lastModified
          );

          if (!isDuplicate) {
            this.Is_ValidFile = true;
            this.uploadedFiles.push(file?.file);
            this.Is_ValidFile = true;
            totalFileSize += file?.file.size;
            this.fileUpload.clear();
            this.uploadError_Visible = false;
          } else {
            this.Is_ValidFile = this.uploadedFiles.length > 0;
            this.uploadError_Visible = true;
            this.uploadError_Message =
              'Duplicate File! This file has already been uploaded.';
          }
        } else {
          this.Is_ValidFile = this.uploadedFiles.length > 0;
          this.uploadError_Visible = true;
          this.uploadError_Message =
            'The total size of selected files exceeds the maximum upload size.';
        }
      } else {
        this.Is_ValidFile = this.uploadedFiles.length > 0;
        this.uploadError_Visible = true;
        this.uploadError_Message =
          'Invalid File! This file format is not allowed.';
      }
    });
  }
  imageView(file: any) {
    this.viewImage.emit(file);
  }

  onBasicUploadAuto(evnt: any) {
    const files = evnt.files || [];
    this.filesForUpdate = files;
    const allFiles = [...files];
    const allowedFormats = this.allowed_formats;
    const maxSize = this.maxFileSize;

    let totalFileSize = this.uploadedFiles.reduce(
      (acc, file) => acc + file.size,
      0
    );

    allFiles.forEach((file) => {
      const fileExtension = file.name.split('.').pop().toLowerCase();
      if (allowedFormats.includes(fileExtension)) {
        if (totalFileSize + file.size <= maxSize) {
          const isDuplicate = this.uploadedFiles.some(
            (existingFile) =>
              existingFile.name === file.name &&
              existingFile.size === file.size &&
              existingFile.lastModified === file.lastModified
          );

          if (!isDuplicate) {
            this.Is_ValidFile = true;
            this.uploadedFiles.push(file);
            this.Is_ValidFile = true;
            totalFileSize += file.size;
            this.fileUpload.clear();
            this.uploadError_Visible = false;
          } else {
            this.uploadedFiles.length
              ? (this.Is_ValidFile = true)
              : (this.Is_ValidFile = false);
            this.uploadError_Visible = true;
            this.uploadError_Message =
              'Duplicate File! This file has already been uploaded.';
          }
        } else {
          this.uploadedFiles.length
            ? (this.Is_ValidFile = true)
            : (this.Is_ValidFile = false);
          this.uploadError_Visible = true;
          this.uploadError_Message =
            'The total size of selected files exceeds the maximum upload size.';
        }
      } else {
        this.uploadedFiles.length
          ? (this.Is_ValidFile = true)
          : (this.Is_ValidFile = false);
        this.uploadError_Visible = true;
        this.uploadError_Message =
          'Invalid File! This file format is not allowed.';
      }
    });
  }

  removeImage(file: any, isFromChip?: any, filesUploaded?: any) {
    if (filesUploaded) {
      this.uploadedFiles = filesUploaded;
    }
    this.uploadedFiles = this.uploadedFiles.filter(
      (uploadedFile) => uploadedFile !== file
    );
    if (isFromChip) {
      this.Submit_Clicked.emit(this.uploadedFiles);
    }
  }
  submit_file() {
    this.Submit_Clicked.emit(this.uploadedFiles);
    this.uploadedFiles = [];
  }

  show() {
    this.show_uploadDialog = true;
  }

  hide() {
    this.show_uploadDialog = false;
    this.uploadError_Message = '';
    this.Is_ValidFile = false;
    this.value = 0;
    clearInterval(this.interval);
    this.genericError_Visible = false;
    this.uploadError_Visible = false;
    this.fileUpload.clear();
  }
}
