import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'form-view',
  templateUrl: './form-view.component.html',
  styleUrls: ['./form-view.component.scss']
})
export class FormViewComponent {

  Is_defaultLayout:boolean = false;

  @Input('DefaultLayout') set _DefaultLayout(value: any) {

    this.Is_defaultLayout = (value) ? value : false;
  }

  @Output() public sectionChange = new EventEmitter<string>();
  
  constructor(){}

  onSectionChange(evnt:any){
    this.sectionChange.emit(evnt);
  }
}
