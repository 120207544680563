<div class="page_content flex flex-column justify-content-between" ScrollSpy [spiedTags]="['SECTION']" (sectionChange)="onSectionChange($event)">
    <div [ngClass]="!Is_defaultLayout ? null : 'page_content_wrapper pl-3 pr-3'">
        <ng-content select="[form-content]"></ng-content>
    </div>

    <div class="page_footer align-content-center ht-10">
        <div class="page_footer_wrapper text-right pl-3 pr-3">
            <ng-content select="[footer]"></ng-content>
        </div>
    </div>
</div>
