<div class="detail-section">

    <ng-container *ngIf="content_type == 'single-view'">
        <p-panel [toggleable]="colleapseable" (onAfterToggle)="toggleChange($event)">
            <ng-template pTemplate="header">
                <div class="grid">
                    <ng-container *ngFor="let header of detail_header">
                        <div class="col-12 md:col-6 lg:col-3 flex flex-column justify-content-center">
                            <ng-container *ngIf="header.headerField; else withoutheader">
                                <label class="detail-Mainheading">{{header.title}}</label>
                            </ng-container>
                            <ng-template #withoutheader>
                                <p *ngIf="!panelClose" class="detail-heading">{{header.title}}</p>
                                <p *ngIf="!panelClose" class="detail-data mt-2">{{header.detail}}</p>
                            </ng-template>
                        </div>
                    </ng-container>
                </div>
            </ng-template>

            <ng-template pTemplate="icon">
                <button pButton class="p-panel-header-icon p-link"></button>
            </ng-template>

            <div *ngIf="HeaderSeparator" class="col-12 flex flex-column justify-content-center mb-2">
                <p class="line"></p>
            </div>

            <div class="grid">
                <ng-container *ngFor="let fields of detail_section">

                    <ng-container *ngIf="fields.subheader; else nosubheader">
                        <div class="col-12 flex flex-column justify-content-center mb-2">
                            <label class="detail-Mainheading">{{fields.title}}</label>
                        </div>
                        <div class="col-12 flex flex-column justify-content-center mb-2">
                            <p class="line"></p>
                        </div>

                    </ng-container>

                    <ng-template #nosubheader>
                        <div class="col-12 md:col-6 lg:col-3 flex flex-column justify-content-center mb-2">
                            <p class="detail-heading mb-1">{{fields.title}}</p>
                            <ng-container *ngIf="fields.statusfield; else noStatusCol">
                                <button style="cursor: default;" pButton type="button" [attr.status]="fields.detail" label="{{fields.detail}}"
                                    class="btn-status p-button-outlined"></button>
                            </ng-container>
                            <ng-template #noStatusCol>
                                <p class="detail-data mt-2">{{fields.detail}}</p>
                            </ng-template>

                        </div>
                    </ng-template>

                    <div *ngIf="fields.endsection" class="col-12 flex flex-column justify-content-center mb-2">
                        <p class="line"></p>
                    </div>
                </ng-container>
            </div>
        </p-panel>
    </ng-container>


    <ng-container *ngIf="content_type == 'tab-view'">
        <p-panel [toggleable]="colleapseable" (onAfterToggle)="toggleChange($event)">

            <ng-template pTemplate="header">
                <div class="grid">

                    <ng-container *ngIf="show_header && panelClose">
                        <div class="flex justify-content-between wd-100">
                            <div class="flex align-items-center">
                                <label class="detail-Mainheading">{{header_title}}</label>
                                <ng-container *ngIf="TrackingInfo">
                                    <div class="trackingInfo" (click)="openTrackingInfo()">
                                        <span>Tracking No:</span>
                                        <label>{{TrackingInfo}}</label>
                                    </div>
                                </ng-container>

                                
                            </div>

                            <ng-container *ngIf="show_masterOrderInfo">
                                <p>Master Order#  663006</p>
                            </ng-container>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="!panelClose">
                        <ng-container *ngFor="let header of detail_header">
                            <div *ngIf="header.section == 'collapsed-header'" class="col-12 md:col-12 lg:col-12 flex align-items-center gap-8">
                                
                                <ng-container *ngFor="let headerdetail of header.data">
                                    <ng-container *ngIf="headerdetail.headerField; else withoutheader">
                                        <label class="detail-Mainheading">{{headerdetail.title}}</label>
                                    </ng-container>
                                    <ng-template #withoutheader>
                                        <div class="flex flex-column justify-content-center">
                                            <p *ngIf="!panelClose" class="detail-heading">{{headerdetail.title}}</p>
                                            <p *ngIf="!panelClose" class="detail-data mt-2">{{headerdetail.detail}}</p>
                                        </div>
                                    </ng-template>
                                </ng-container>
                                
                            </div>
                        </ng-container>
                    </ng-container>

                </div>
            </ng-template>

            <ng-template pTemplate="icon">
                <button pButton class="p-panel-header-icon p-link"></button>
            </ng-template>

            <div *ngIf="HeaderSeparator && show_header" class="col-12 flex flex-column justify-content-center mb-2">
                <p class="line"></p>
            </div>

            <div class="grid">
                <ng-container *ngFor="let fields of detail_DisplayData">

                    <ng-container *ngIf="fields.subheader; else nosubheader">
                        <div class="col-12 flex flex-column justify-content-center mb-2">
                            <p class="line"></p>
                        </div>
                        <div class="col-12 flex flex-column justify-content-center mb-2">
                            <label class="detail-Mainheading">{{fields.title}}</label>
                        </div>
                        <div class="col-12 flex flex-column justify-content-center mb-2">
                            <p class="line"></p>
                        </div>

                    </ng-container>

                    <ng-template #nosubheader>
                        <div class="col-12 md:col-6 lg:col-3 flex flex-column justify-content-center mb-2">
                            <p class="detail-heading mb-1">{{fields.title}}</p>
                            <ng-container *ngIf="fields.statusfield; else noStatusCol">
                                <button style="cursor: default;" pButton type="button" [attr.status]="fields.detail" label="{{fields.detail}}"
                                    class="btn-status p-button-outlined"></button>
                            </ng-container>
                            <ng-template #noStatusCol>
                                <p class="detail-data mt-2">{{fields.detail}}</p>
                            </ng-template>

                        </div>
                    </ng-template>

                    <div *ngIf="fields.endsection" class="col-12 flex flex-column justify-content-center mb-2">
                        <p class="line" *ngIf="fields.separator"></p>
                    </div>
                </ng-container>
            </div>
            
            <div class="grid justify-content-end gap-2 pr-5 mt-3 tab-NavBtn">
                <p-button icon="pi i-left" styleClass="p-button-rounded p-button-text p-button-raised" (onClick)="ChangeTab('prev')"></p-button>
                <p-button icon="pi i-right" styleClass="p-button-rounded p-button-text p-button-raised" (onClick)="ChangeTab('next')"></p-button>
            </div>
            
            <div class="grid justify-content-center gap-2 mt-3">
                <ng-container *ngFor="let section of detail_section; let ind = index">
                    <div class="tab_dots" [ngClass]="ind == activeTabIndex ? 'active' : ''"
                        (click)="detailTabChange(ind, section)"></div>
                </ng-container>
            </div>
            
        </p-panel>
    </ng-container>
</div>