<!-- <div class="page_content ht-85">
    <div [ngClass]="!Is_defaultLayout ? null : 'page_content_wrapper mt-3'">
        <ng-content select="[detail-content]"></ng-content>
    </div>
</div> -->

<div class="page_content flex flex-column justify-content-between ht-85 gap-3 pl-3 pl-4">
    <div [ngClass]="!Is_defaultLayout ? 'page_content_wrapper' : 'page_content_wrapper mt-3'">
        <ng-content select="[detail-content]"></ng-content>
    </div>

    <div class="page_footer ht-10 flex align-items-center justify-content-end">
        <ng-content select="[footer]"></ng-content>
    </div>
</div>

