import { Component, EventEmitter, Output } from '@angular/core';
import {DialogService} from 'primeng/dynamicdialog';
@Component({
  selector: 'app-popup-grid',
  templateUrl: './popup-grid.component.html',
  styleUrls: ['./popup-grid.component.scss']
})
export class PopupGridComponent {

  selectedRows: any;
  gridColumns: any[] = [
    { field: 'itemCode', header: 'Item Code', visible: true, islink: true, align: 'left', styleClass: 'wd-20', inputStyleClass:'wd-40' },
    { field: 'enteredprice', header: 'Entered Price', visible: true, islink: true, align: 'center', styleClass: 'wd-15', inputStyleClass:'wd-40' },
    { field: 'updatedprice', header: 'Updated Price', visible: true, fieldType: 'input',islink: true, align: 'center', styleClass: 'wd-20', inputStyleClass:'wd-40' },
    { field: 'defaultmargin', header: 'Default Margin%', visible: true, islink: true, align: 'center', styleClass: 'wd-20', inputStyleClass:'wd-40' },
    { field: 'requestedmargin', header: 'Requested Margin%', visible: true, islink: true, align: 'center', styleClass: 'wd-20', inputStyleClass:'wd-40' }
  ];

  gridDataSource: any[] = [
    {itemCode: '038X302X1600-G', enteredprice: '$120', updatedprice: '', defaultmargin: '20%', requestedmargin: '10%'},
    {itemCode: '038X302X1600-G', enteredprice: '$140', updatedprice: '', defaultmargin: '15%', requestedmargin: '7%'},
  ];

  constructor(public dialogService: DialogService){}

  ngOnInit(){
     
  }

  row_Selection(rowData: any){
    if(rowData.length>0) this.selectedRows = rowData;
    
  }
  // Row selected data will be send through API 
  approve(){
    if(this.selectedRows?.length>0) console.log('data to API', this.selectedRows);
   
  }

  // cancel popup 
  cancel(){
  this.dialogService.dialogComponentRefMap.forEach(dialog => {
    dialog.destroy();
  });
  }

}
