import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { OverlayPanel } from 'primeng/overlaypanel';

@Component({
  selector: 'acv-password-input',
  templateUrl: './password-input.component.html',
  styleUrls: ['./password-input.component.scss'],
})
export class PasswordInputComponent {
  value: any;
  wordCount: any;
  form_data: any;
  overlayWidth: string = '0px';
  passwordRequirements: {
    length: boolean;
    specialChar: boolean;
    number: boolean;
    capitalLetter: boolean;
  } = {
    length: false,
    specialChar: false,
    number: false,
    capitalLetter: false,
  };
  passMatch: boolean = false;
  emptyFieldPass: boolean = false;
  emptyFieldConfirm: boolean = false;
  resp: any = undefined;
  passValidation: boolean = false;
  @Input() form: FormGroup;
  @Input() ControlName: string;
  @Input() ControlType: string = 'password';
  @Input() Required: boolean;
  @Input() overlay: boolean = false;
  @Input() ControlLabelText: string;
  @Input() PlaceHolderText: string = 'Enter Here';
  @Input() IconClass: string;
  @Input() IconPosition: string;
  @Input() ClassList: string;
  @Input() ShowWordCount: boolean = true;
  @Output() iconClick = new EventEmitter<boolean>();
  @Input() IsSubmited: boolean = false;

  @ViewChild('passwordInput') passwordInput: ElementRef;
  @ViewChild('op') overlayPanel: OverlayPanel;

  words: number = 0;
  hide: boolean = false;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.form_data = this.form.value;
  }

  toggleOverlay(event: Event) {
    this.overlayPanel?.toggle(event);
  }

  ngAfterViewInit(): void {
    this.form.controls[this.ControlName].setValue(
      this.form_data[this.ControlName]
    );
    this.wordCounter();
    const inputWidth = this.passwordInput.nativeElement.offsetWidth;
    this.overlayWidth = `${inputWidth}px`;
    this.cdr.detectChanges();
  }

  checkPasswordRequirements() {
    this.emptyFieldPass = false;
    this.emptyFieldConfirm = false;
    let password: string | null = this.form.get('password')?.value;

    if (password) {
      this.passwordRequirements.length = password.length >= 8;
      this.passwordRequirements.specialChar = /[!@#$%^&*(),.?":{}|<>]/g.test(
        password
      );
      this.passwordRequirements.number = /\d/.test(password);
      this.passwordRequirements.capitalLetter = /[A-Z]/.test(password);
      this.passValidation = this.passwordRequirements.length && this.passwordRequirements.specialChar && this.passwordRequirements.number && this.passwordRequirements.capitalLetter;
    } else {
      this.passwordRequirements.length = false;
      this.passwordRequirements.specialChar = false;
      this.passwordRequirements.number = false;
      this.passwordRequirements.capitalLetter = false;
      this.passValidation = false;
      this.resp = undefined;
    }
    this.passValidation ? this.passMatch = true : this.passMatch = false
  }

  wordCounter() {
    //alert(this.text.nativeElement.value)
    this.wordCount = this.passwordInput ? this.passwordInput.nativeElement.value.split(/\s+/) : 0;
    this.words = this.passwordInput.nativeElement.value.length;
  }

  showPassword() {
    this.hide = !this.hide;
    this.iconClick.emit(this.hide);
    this.ControlType = !this.hide ? 'password' : 'text';
  }
}
