import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlighter'
})
export class HighlighterPipe implements PipeTransform {

  transform(value: any, args: any, type: string): unknown {
    if(!args || !value || value === '-'){

      return value;
    } 
    if(type==='full'){
      const re = new RegExp(args, 'gi');
      value= value.replace(re, '<mark>$&</mark>');
    }
    else{
      const re = new RegExp(args, 'igm');
      value= value.replace(re, '<mark">$&</mark>');
    }

      return value;
  }

}
