import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { ApiHttpService } from 'src/app/core/services/apiHttpcall.service';
import { interval, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss'],
})
export class OtpComponent {
  @Output() onSuccess = new EventEmitter<string>();
  @Input() email: string;

  resp: any = undefined;
  IsLoading: boolean = false;
  submitted: boolean = false;

  timerSubscription: Subscription;
  timer: number = 60;
  displayTime: string = this.formatTime(this.timer);
  resendEnabled: boolean = true;
  emptyOTP: boolean = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private UILoader: NgxSpinnerService,
    private httpService: ApiHttpService,
    private el: ElementRef,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    this.startTimer();
  }

  handleInput(event: Event) {
    this.emptyOTP = false;
    let input = event.target as HTMLInputElement;
    if (input.value === '') {
      this.resp = undefined;
    }
  }

  otpForm = new FormGroup({
    otp: new FormControl<any>(null, [Validators.required]),
    email: new FormControl<any>(null),
  });

  async handleSubmit() {
    this.submitted = true;
    this.focusIN();
    if (this.otpForm.get('otp')?.valid) {
      this.IsLoading = true;
      let payload = this.otpForm.value;
      payload.email = this.email;
      this.resp = await this.httpService.post(`/otp/verification`, payload);

      if (this.resp.status == 200) {
        this.IsLoading = false;
        this.onSuccess.emit(this.email);
      } else {
        this.IsLoading = false;
        this.submitted = false;
      }
    }
    else{
      this.emptyOTP = true;
    }
  }

  async resendOtp() {
    if (this.resendEnabled) {
      this.IsLoading = true;
      this.resp = await this.httpService.post(`/password/forgot`, {
        email: this.email,
      });
      if (this.resp.status == 200) {
        this.startTimer();
        this.IsLoading = false;
      } else {
        this.IsLoading = false;
      }
    }
  }

  startTimer() {
    this.resendEnabled = false;
    this.timer = 60;
    this.displayTime = this.formatTime(this.timer);
    this.timerSubscription = interval(1000)
      .pipe(take(this.timer))
      .subscribe(
        () => {
          --this.timer;
          this.displayTime = this.formatTime(this.timer);
        },
        () => {},
        () => {
          this.timerSubscription.unsubscribe();
          this.resendEnabled = true;
        }
      );
  }
  
  formatTime(time: number): string {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${this.pad(minutes)}:${this.pad(seconds)}`;
  }
  
  pad(num: number): string {
    return num < 10 ? `0${num}` : num.toString();
  }

  focusIN() {
    const invalidControl = this.el.nativeElement.querySelector('input');
    if (invalidControl) {
      invalidControl.focus();
    }
    this.resp = undefined;
  }
}
