import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-download-confirmed-popup',
  templateUrl: './download-confirmed-popup.component.html',
  styleUrls: ['./download-confirmed-popup.component.scss']
})
export class DownloadConfirmedPopupComponent {
  title: string = ' CSV';
  show_Dialog: boolean = false;
  @Input() moduleName: string = '';
  @Input() action: string = '';

  show() {
    this.show_Dialog = true;
    setTimeout(() => { this.show_Dialog = false }, 2000)
  }

  hide() {
    this.show_Dialog = false;
  }
}