import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { ApiHttpService } from 'src/app/core/services/apiHttpcall.service';

@Component({
  selector: 'reason-dialog',
  templateUrl: './reason-dialog.component.html',
  styleUrls: ['./reason-dialog.component.scss']
})
export class ReasonDialogComponent {
  show_Dialog: boolean = false;
  id:number;
  data: any[] = [];
  rejectForm: any;
  is_form_submit: boolean = false;
  isLoading: boolean = false;
  status:number = 7;

  @Output() onSuccess: EventEmitter<any> = new EventEmitter();

  constructor(public dialogService: DialogService, private fb: FormBuilder,
    private httpService: ApiHttpService, private messageService: MessageService, private confirmationService: ConfirmationService,) { }

  // cancel upload
  uploadCancel() {

  }

  initializeForm() {
    this.rejectForm = this.fb.group({
      reason: ['', Validators.compose([Validators.required])],
    });
  }

  // cancel popup 
  cancel() {
    this.dialogService.dialogComponentRefMap.forEach(dialog => {
      dialog.destroy();
    });
  }

  show() {
    this.show_Dialog = true;
    this.initializeForm();
  }

  hide() {
    this.is_form_submit = false;
    this.show_Dialog = false;
  }

  async handleSubmitForm() {
    this.is_form_submit = true;

    if (this.rejectForm.valid) {
      this.isLoading = true;
      let resp = await this.httpService.put(`/trip/${this.id}/update-status?status=${this.status}&reason=${this.rejectForm.controls['reason'].value}`, null);

      if (resp?.status == 200) {
        this.onSuccess.emit();
        this.is_form_submit = false;
        this.hide();
        this.messageService.add({ severity: 'customSuccess', 
          icon: 'i-check-circle', 
          summary: 'Success', 
          detail: 'Status updated successfully', 
          key: 'app_notiy' });
      }
      else {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: resp?.error?.message, key: 'app_notiy' });
      }
      this.isLoading = false;
    }
  }

  handleCancelConfirm() {
    if (this.rejectForm.controls['reason'].value) {
      this.confirmationService.confirm({
        message: 'All the saved data will be lost. Are you sure?',
        header: 'Cancel',
        icon: 'i-warn',
        acceptLabel: 'Yes',
        rejectLabel: 'No',
        accept: () => {
          this.hide();
        },
      });
    }
    else {
      this.hide();
    }

  }
}
