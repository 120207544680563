import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DragDropModule } from 'primeng/dragdrop';

@Component({
  selector: 'acv-drag-drop',
  templateUrl: './acv-drag-drop.component.html',
  styleUrls: ['./acv-drag-drop.component.scss'],
})
export class AcvDragDropComponent {
  available_associations: any[] = [];
  dropped_associations: any[] = [];
  actions_list: any[] = [];
  draggedBlock: any = null;
  starter: number;
  isDraggingOver: boolean = false;
  dragOverIndex: number | null = null;
  filtered_associations: any[] = [];
  original_associations: any[] = [];

  @Input() form: FormGroup;
  @Input() formControlName: string;
  @Input() viewMode: string = 'form';
  @Input() serviceName: string = '';
  @Input() labelText: string = '';
  @Input() Required: boolean = false;
  @Input() selectedItems: any[] = [];

  @Input('AvailableAssociations') set _AvalaibleAssociations(value: any) {
    this.available_associations = value;
    this.original_associations = [...value];
    this.filtered_associations = [...value];
  }

  @Output() onActionChanged: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {
    this.updateSelectedItems();
  }

  ngOnChanges(): void {
    this.updateSelectedItems();
  }

  updateSelectedItems(): void {
    if (this.selectedItems) {
      this.dropped_associations = this.selectedItems.map((id) =>
        this.available_associations.find((action) => action.id === id)
      );
      this.filtered_associations = this.available_associations.filter(
        (action) => !this.selectedItems.includes(action.id)
      );
    }
  }

  dragStart(block: any, indu: number) {
    this.draggedBlock = block;
    this.starter = indu;
  }

  dragEnd() {
    this.draggedBlock = null;
  }

  drop(event: any, indu: number) {
    const draggedIndex = this.dropped_associations.indexOf(this.draggedBlock);

    if (draggedIndex > -1) {
      // If the dragged block is already in dropped_associations, change its index
      this.dropped_associations.splice(draggedIndex, 1); // Remove from current position
      this.dropped_associations.splice(indu, 0, this.draggedBlock); // Insert at new position
      this.selectedItems.splice(draggedIndex, 1); // Remove from current position
      this.selectedItems.splice(indu, 0, this.draggedBlock.id); // Insert at new position
    } else {
      // Remove the item from the filtered list
      this.filtered_associations.splice(this.starter, 1);
      // Add the item to the dropped list
      this.dropped_associations.push(this.draggedBlock);
    }

    this.draggedBlock = null;
    this.dragOverIndex = null;
  }

  onDragEnter(event: any, indu: number) {
    this.isDraggingOver = true;
    this.dragOverIndex = indu;
  }

  onActionSelect(action: any) {}

  addToOrginals(index: number, action: any) {
    // Remove the item from dropped_associations
    const item = this.dropped_associations.splice(index, 1)[0];

    // Add the item to filtered_associations at the specified index
    this.filtered_associations.splice(index, 0, item);
    this.original_associations.splice(index, 0, item);

    // Update the selectedItems array
    const selectedIndex = this.selectedItems.indexOf(action.id);
    if (selectedIndex !== -1) {
      this.selectedItems.splice(selectedIndex, 1);
    }
  }

  onCheckboxChange(index: number) {
    // Get and remove the item from the filtered_associations
    const item = this.filtered_associations.splice(index, 1)[0];
  
    // Add the item to dropped_associations
    this.dropped_associations.push(item);
  
    // Update the selectedItems array
    this.selectedItems.push(item.id);
  
    // Update the original_associations array
    const originalIndex = this.original_associations.findIndex((a) => a.id === item.id);
    if (originalIndex !== -1) {
      this.original_associations.splice(originalIndex, 1);
    }
  
    this.onActionChanged.emit(this.selectedItems);
  }
  
  filterAssociations(event: Event) {
    const searchText = (event.target as HTMLInputElement).value.toLowerCase();
    if (searchText) {
      this.filtered_associations = this.original_associations.filter((action) => {
        const name = action.name.toLowerCase();
        // Check for complete word match
        if (name.split(/\s+/).includes(searchText)) {
          return true;
        }
        // Check for alphabetical match
        return name.includes(searchText);
      });
    } else {
      this.filtered_associations = [...this.original_associations];
    }
  }
}
