<app-download-confirmed-popup #confirmedUpload_ref [moduleName]="moduleName | titlecase" [action]="'upload'"></app-download-confirmed-popup>
<p-dialog
  [styleClass]="'popup_import'"
  [draggable]="false"
  [closable]="false"
  [style]="{ width: '40%' }"
  [modal]="true"
  [visible]="show_ImportDialog"
>
  <ng-template pTemplate="header">
    <div
      class="flex wd-100 dialog_header_wrapper align-items-center justify-content-between ng-star-inserted"
    >
      <span class="text-xl font-bold">{{ dialog_header }}</span>
      <p-button
        icon="pi pi-times"
        (onClick)="hide()"
        styleClass="p-button-rounded p-button-danger p-button-text p-button-raised"
      ></p-button>
    </div>
  </ng-template>

  <div
    class="import_content_wrapper"
    [ngClass]="
      import_stage == 'upload_detail'
        ? null
        : genericError_Visible
        ? 'flex gap-4 flex-column align-items-center justify-content-end'
        : 'flex flex-column align-items-center justify-content-center'
    "
    DragndropFile
    (files)="onFileDrop($event)"
  >
    <ng-container>
      <div><img src="../../../../assets/images/upload.svg" /></div>

      <p class="content_label mt-2">
        Drag and Drop file here or
        <span
          ><p-fileUpload
            #fubauto
            mode="basic"
            [classList]="' import_browse'"
            [accept]="'.csv, .xls, .xlsx'"
            (onSelect)="onBasicUploadAuto($event)"
            chooseLabel="Choose file"
          ></p-fileUpload
        ></span>
      </p>
      <p-messages
        severity="error"
        [className]="'import-error'"
        *ngIf="importError_Visible"
      >
        <ng-template pTemplate>
          <p-button
            icon="pi pi-times"
            (onClick)="fubauto.clear(); importError_Visible = false"
            styleClass="p-button-rounded p-button-danger p-button-text p-button-raised"
          ></p-button>
          <span class="custom-message">{{ importError_Message }}</span>
        </ng-template>
      </p-messages>
    </ng-container>
  </div>
  <div class="flex justify-content-between mt-1">
    <span class="typo-supported-format">Support formats: XLS, XLSX, CSV</span>
    <span class="typo-supported-format">Maximum size: 25 MB</span>
  </div>
  <div *ngIf="import_stage !== 'upload_progress'" class="file-info mt-3">
    <div class="flex align-items-center">
      <div class="flex-1">
        <div class="flex gap-2">
          <img
            style="width: 20px"
            src="../../../assets/images/excel-logo.png"
          />
          <p class="font-poppin fw-600 text-color-black fs-12">Sample File</p>
        </div>
        <p class="font-poppin fw-500 text-color-grey3 fs-10 mt-3">
          You can download the attached example and use them as a starting point
          for your own file.
        </p>
      </div>
      <div>
        <p-button
          type="button"
          label="Download"
          class="p-button-text elevated-btn secondary"
          (click)="downloadTemplate()"
        ></p-button>
      </div>
    </div>
  </div>

  <div
    *ngIf="import_stage === 'upload_progress'"
    [ngStyle]="{ border: error_Visible || !Is_ValidFile ? '1px solid red' : 'none' }"
    class="file-info mt-3"
  >
    <div class="flex align-items-center">
      <div class="flex-1">
        <div class="flex justify-content-between">
          <div class="flex gap-2">
            <img
              style="width: 20px;"
              src="../../../assets/images/excel-logo.png"
            />
            <p class="font-poppin fw-600 text-color-black fs-12">
              {{file_name}}
            </p>
          </div>
          <div>
            <i class="cursor-pointer pi pi-times" (click)="uploadCancel()"></i>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="error_Visible" class="error-container p-3 mt-3">
    <div *ngFor="let error of errors; let i = index">
      <div class="flex align-items-center gap-2" id="error-{{ i }}">
        <div>
          <img
            src="../../../assets/images/close-icon.svg"
            width="8px"
            height="8px"
            alt="close"
          />
        </div>
        <div class="font-poppin fw-500 fs-10 text-color-red">{{ error }}</div>
      </div>
    </div>
  </div>

  <ng-template pTemplate="footer">
    <div class="flex align-items-center flex gap-3 justify-content-end">
      <p-button
        type="button"
        label="Cancel"
        class="p-button-text elevated-btn secondary"
        (click)="hide()"
      ></p-button>
      <p-button
        type="button"
        label="Upload"
        class="p-button-text elevated-btn primary mr-2"
        (onClick)="submit_file(file)"
        [disabled]="!Is_ValidFile"
      ></p-button>
    </div>
  </ng-template>
</p-dialog>
