<div class="input-elem" [formGroup]="form">
  <p class="text-control-label mb-2">
    {{ ControlLabelText }} <sup *ngIf="Required">*</sup>
  </p>
  <!-- <p-multiSelect styleClass="w-full acv-multiselect" panelStyleClass="acv-multiselect" [appendTo]="'body'" [formControlName]="ControlName" [options]="Options_List" [filter]="Filter"
        [showHeader]="false" [optionLabel]="OptionLabel" [defaultLabel]="PlaceHolderText" [selectedItemsLabel]="'{0} items selected'" >
    </p-multiSelect> -->
  <p-multiSelect
    styleClass="w-full acv-multiselect"
    panelStyleClass="acv-multiselect"
    [appendTo]="'body'"
    [formControlName]="ControlName"
    [options]="Options_List"
    [filter]="Filter"
    [showHeader]="true"
    [resetFilterOnHide]="true"
    [optionLabel]="OptionLabel"
    [optionValue]="OptionValue"
    [defaultLabel]="PlaceHolderText"
    [maxSelectedLabels]="0"
    [selectedItemsLabel]="'{0} items selected'"
    [overlayOptions]="{ styleClass: 'multiselect-overlayPanelClass' }"
    [ngClass]="{
      'p-inputtext-error': !form.controls[ControlName].valid && IsSubmited,
      'ng-dirty': !form.controls[ControlName].valid && IsSubmited,
      'ng-invalid': !form.controls[ControlName].valid && IsSubmited
    }"
    [required]="Required"
  >
    <ng-template let-item pTemplate="item">
      <div style="word-break: break-all; white-space: pre-wrap">
        {{ item[OptionLabel] }}
      </div>
    </ng-template>
  </p-multiSelect>
  <div
    class="mt-1 text-right"
    [ngClass]="{
      'flex justify-content-between':
        !form.controls[ControlName].valid && IsSubmited
    }"
  >
    <p
      class="fs-10 fw-400 font-poppin text-color-red"
      *ngIf="!form.controls[ControlName].valid  && IsSubmited"
    >
      Please fill this required field
    </p>
  </div>
</div>
