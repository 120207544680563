import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiHttpService } from 'src/app/core/services/apiHttpcall.service';
import { DataResolverService } from 'src/app/core/services/data-resolver.service';
import { ProfileService } from 'src/app/core/services/profile.service';

@Component({
  selector: 'auth-page',
  templateUrl: './auth-page.component.html',
  styleUrls: ['./auth-page.component.scss'],
})
export class AuthPageComponent implements OnInit {
  loginPage: boolean;
  forgetPasswordPage: boolean;
  otpPage: boolean = false;
  reset_pwd: boolean = false;
  email: string;
  resp: any = undefined;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private httpService: ApiHttpService,
    private profileService: ProfileService,
    private dataResolver: DataResolverService
  ) {
    this.loginPage = false;
    this.forgetPasswordPage = false;
    this.otpPage = false;
  }

  async handleRemberMe() {
    this.dataResolver.get_UserProfile().subscribe((profileData) => {
      return this.router.navigate(['/setup/services/list']);
    });
  }

  handleSuccess(email: string) {
    if (this.forgetPasswordPage) {
      this.forgetPasswordPage = false;
      this.otpPage = true;
      this.email = email;
    } else if (this.otpPage) {
      this.otpPage = false;
      this.reset_pwd = true;
      this.email = email;
    }
  }

  navigateToLogin(){
    this.router.navigate(['/login']);
  }


  ngOnInit() {
    this.route.url.subscribe((url) => {
      this.loginPage = this.router.url.includes('login');
      this.forgetPasswordPage = this.router.url.includes('forgot-password');

      if (this.loginPage) {
        this.handleRemberMe();
      }
    });
}
}
