import { Component } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';
@Component({
  selector: 'export-popup',
  templateUrl: './export-popup.component.html',
  styleUrls: ['./export-popup.component.scss']
})
export class ExportPopupComponent {

  fmdate: any = undefined;
  todate: any = undefined;
  show_ExportDialog:boolean=false;
  dialog_header:string = "Export";

  constructor(public dialogService: DialogService, private messageService: MessageService) { }

  submit_file(){
    console.log('fmdate', this.fmdate);
    console.log('todate', this.todate);
    this.show_ExportDialog = false;
  }
  show(){
    this.show_ExportDialog = true;
  }

  hide(){
    this.show_ExportDialog = false;
  }
}
